import { createContext, useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const PopupContext = createContext<PopupContextProps>({} as PopupContextProps);

// eslint-disable-next-line react-refresh/only-export-components
export function usePopup() {
  return useContext(PopupContext);
}

export function PopupProvider({ children }: any) {
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [text, setText] = useState("");
  const [onConfirmFunc, setOnConfirm] = useState<() => void>();
  const [loading, setLoading] = useState(false);

  const popup = ({ type, msg, onConfirm }: any) => {
    if (!type) {
      setShow(true);
      setText(msg);
    }
    if (type === "confirm") {
      setOnConfirm(() => onConfirm);
      setShowConfirm(true);
      setText(msg);
    }
  };

  const value: PopupContextProps = {
    popup,
  };

  return (
    <PopupContext.Provider value={value}>
      <Modal
        style={{ color: "black" }}
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>{text}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      {showConfirm && (
        <Modal
          style={{ color: "black" }}
          show={showConfirm}
          onHide={() => setShowConfirm(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>{text}</Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowConfirm(false)}>Close</Button>
            <Button
              disabled={loading}
              onClick={async () => {
                setLoading(true);
                await onConfirmFunc?.();
                setLoading(false);
                setShowConfirm(false);
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {children}
    </PopupContext.Provider>
  );
}
