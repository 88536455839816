import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import "@Styles/tailwind.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@Styles/index.css";

Sentry.init({
  dsn: "https://cf3119781f5823d04b47081c7b9f41e1@o4506961333846016.ingest.us.sentry.io/4506961350885376",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["dev.app.tastyplan.rocks", "app.tastyplan.rocks"],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  autoSessionTracking: false,
  enabled: process.env.NODE_ENV !== "development",
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <Router>
    <App />
  </Router>
);
