import { lazy, Suspense, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { AuthProvider } from "@Contexts/AuthContext";
import { DataProvider } from "@Contexts/DataContext";
import SideBar from "@Components/SideBar";

const Login = lazy(() => import("@Pages/login"));
const SignUp = lazy(() => import("@Pages/signup"));
const NavigationBar = lazy(() => import("@Components/NavigationBar"));
const Onboarding = lazy(() => import("@Pages/onboarding"));
const Home = lazy(() => import("@Pages/index"));
const Dashboard = lazy(() => import("@Pages/dashboard"));

import "@Utility/index";
import { PopupProvider } from "@Contexts/PopupContext";
import NotificationsProvider from "@Contexts/NotificationContext";

import CompanyLogo from "@Assets/logo.png";

export default function App() {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <div className="overflow-y-hidden h-screen w-screen">
        <AuthProvider>
          <DataProvider>
            <NotificationsProvider>
              <PopupProvider>
                <Suspense
                  fallback={
                    <FallBack expanded={expanded} setExpanded={setExpanded} />
                  }
                >
                  <NavigationBar />
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/onboarding" element={<Onboarding />} />
                    <Route
                      path="/dashboard/*"
                      element={
                        <Dashboard
                          expanded={expanded}
                          setExpanded={setExpanded}
                        />
                      }
                    />
                  </Routes>
                </Suspense>
              </PopupProvider>
            </NotificationsProvider>
          </DataProvider>
        </AuthProvider>
      </div>
    </>
  );
}

const FallBack = ({ expanded, setExpanded }: any) => {
  return (
    <div className="flex text-black">
      <SideBar expanded={expanded} setExpanded={setExpanded} />
      <div className="w-full py-4 px-5 bg-white transition-all h-screen grid place-items-center">
        <img src={CompanyLogo} width={30} />
      </div>
    </div>
  );
};
