import { useState, createContext, useContext } from "react";
import { Toast } from "react-bootstrap";

const NotificationContext = createContext<NotificationsContextProps>(
  {} as NotificationsContextProps
);

// eslint-disable-next-line react-refresh/only-export-components
export function useNotifications() {
  return useContext(NotificationContext);
}

export default function NotificationsProvider({ children }: any) {
  const [notifications, setNotifications] = useState<LocalNotification[]>(
    [] as LocalNotification[]
  );

  const notify = (
    title: string,
    message: string,
    variant: NotificationVariant
  ) => {
    const notification_data = {
      title,
      message,
      variant,
      id: Math.round(Math.random() * 50000),
    };
    setNotifications([
      ...notifications,
      notification_data,
    ] as LocalNotification[]);

    setTimeout(() => {
      delete notifications.filter(
        (notification) => notification.id === notification_data.id
      )[0];
      setNotifications(notifications.filter((notification) => notification));
    }, 5000);
  };

  const values: NotificationsContextProps = {
    notify,
  };

  return (
    <NotificationContext.Provider value={values}>
      {children}
      <div className="flex flex-col gap-2 fixed bottom-5 right-3 z-[9999]">
        {notifications.map((notification, idx) => (
          <Toast key={idx} id="notification-slide" className="relative">
            <Toast.Header
              style={{ background: "rgb(34 197 94)" }}
              className="text-white"
            >
              <strong className="me-auto">{notification.title}</strong>
            </Toast.Header>
            <Toast.Body className="text-black">
              {notification.message}
            </Toast.Body>
            <div
              className="h-[2px] bottom-0 bg-green-500"
              id="notification-indicator"
            ></div>
          </Toast>
        ))}
      </div>
    </NotificationContext.Provider>
  );
}
