/**
 *
 * Used for fetching external data from api.
 *
 * @class DataEngine
 */
class DataEngine {
  private sources: Source[];
  private org_id: string;
  /**
   *
   * Keeps all the fetched data from init function.
   *
   * @type {ExternalData}
   * @memberof DataEngine
   */
  data: ExternalData = {};

  /**
   * Creates an instance of DataEngine.
   * @param {string} org_id organization id of the organizations that the data is to be fetched for
   * @param {Source[]} sources list of sources from the user object.
   * @memberof DataEngine
   */
  constructor(org_id: string, sources: Source[]) {
    this.sources = sources;
    this.org_id = org_id;
  }

  /**
   *
   * Fetches all the external data for the user saves it in `data` property.
   *
   * @memberof DataEngine
   */
  async init() {
    return await Promise.all(
      this.sources.map(async (source) => {
        const inventory = await fetch(
          `${import.meta.env.VITE_INGEST_API}/ingest/${
            this.org_id
          }/inventory?subsidiaryId=${source.subsidiaryId}`
        );
        const products = await fetch(
          `${import.meta.env.VITE_INGEST_API}/ingest/${
            this.org_id
          }/products?subsidiaryId=${source.subsidiaryId}`
        );
        const pending_orders = await fetch(
          `${import.meta.env.VITE_INGEST_API}/ingest/${
            this.org_id
          }/pending_orders?subsidiaryId=${source.subsidiaryId}`
        );
        this.data[source.subsidiaryId] = {
          inventory: await inventory.json(),
          products: await products.json(),
          pending_orders: await pending_orders.json(),
        };
      })
    );
  }

  /**
   *
   * Gets pre-fetched data for a specific subsidiary.
   *
   * @param {string} subsidiaryId
   * @return {*}
   * @memberof DataEngine
   */
  get(subsidiaryId: string) {
    return this.data[subsidiaryId];
  }
}

export default DataEngine;
