/**
 *
 * Converts csv string to json array of object
 *
 * @param {string} data csv data in string form
 * @param {string} [delimiter=","] separator used in csv data
 * @return {*} array of objects
 */
const csv_to_json = (data: string, delimiter = ",") => {
  const titles = data
    .slice(0, data.indexOf("\n"))
    .split(delimiter)
    .map((title) => title.trim().replace("ï»¿", ""));
  return data
    .slice(data.indexOf("\n") + 1)
    .split("\n")
    .map((v) => {
      const values = v
        .split(delimiter)
        .map((value) => value.replaceAll(`\"`, ""));
      return titles.reduce(
        (obj: any, title: any, index: any) => (
          (obj[title] = values[index]), obj
        ),
        {}
      );
    });
};

export default csv_to_json;
