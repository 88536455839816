import { TbPackages } from "react-icons/tb";
import { AiOutlineOrderedList } from "react-icons/ai";
import { TbTruckDelivery } from "react-icons/tb";
import { GiFactory } from "react-icons/gi";
import { MdWarehouse } from "react-icons/md";
import { MdOutlineShareLocation } from "react-icons/md";
import { GoOrganization } from "react-icons/go";
import { MdOutlineBrandingWatermark } from "react-icons/md";
import { CiSettings } from "react-icons/ci";
// import { RiExpandRightLine } from "react-icons/ri";
import { useSpring, a } from "react-spring";
// import { useAuth } from "@Contexts/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import CompanyLogo from "@Assets/logo.png";
import LogoText from "@Assets/logo-text.png";
import { useData } from "@Contexts/DataContext";

export default function SideBar({ expanded, setExpanded }: any) {
  const location = useLocation();
  const navigate = useNavigate();

  const { organizations, organizationId, setOrganizationId, organization } =
    useData();

  const isActive = (pathname: string): boolean => {
    return location.pathname.includes(pathname);
  };

  const expandAnimation = useSpring({
    width: expanded ? 280 : 80,
  });
  const sepratorExpandAnimation = useSpring({
    width: expanded ? 200 : 40,
    marginLeft: -4,
  });
  // const rotateAnimation = useSpring({ rotateZ: expanded ? 180 : 0 });
  const removeAnimation: any = useSpring({
    opacity: expanded ? 1 : 0,
    marginRight: -750,
    marginBottom: 0,
    marginLeft: expanded ? 4 : -10,
    pointerEvents: expanded ? "auto" : "none",
  });

  const dot: any = useSpring({
    top: isActive("/organizations")
      ? 114
      : isActive("/brands")
      ? 179
      : isActive("/locations")
      ? 243
      : isActive("/warehouses")
      ? 308
      : isActive("/suppliers")
      ? 370
      : isActive("/products")
      ? 435
      : isActive("/catalogs")
      ? 499
      : isActive("/factories")
      ? 566
      : location.pathname === "/dashboard"
      ? 24
      : -5,
  });

  return (
    <>
      <a.div
        style={expandAnimation}
        className="h-screen w-[80px] bg-[#5E17EB] text-white flex flex-col justify-between relative"
        onMouseOver={() => setExpanded(true)}
        onMouseLeave={() => setExpanded(false)}
      >
        <a.div
          style={dot}
          id="dot"
          className="rounded-full absolute bg-[#FC2947] left-[-10px] w-[20px] h-[20px]"
        />
        <div className="flex flex-col pl-[20px] mt-[-8px]">
          <div
            className="flex justify-start items-center gap-2 mt-4 cursor-pointer "
            onClick={() => navigate("/dashboard")}
          >
            <div className="grid place-items-center w-[40px] h-[40px] ml-[-5px] bg-[#5E17EB]">
              <img
                src={CompanyLogo}
                style={{
                  // filter: location.pathname === "/dashboard"? "#fff" : "#FC2947",
                  width: 40,
                }}
              />
            </div>
            <a.h5 style={removeAnimation}>
              <img src={LogoText} className="h-[44px] ml-[-6px] -mb-1" />
            </a.h5>
          </div>
          <a.div
            style={sepratorExpandAnimation}
            className="h-[1px] bg-white mt-4"
          />
          <div
            className="flex justify-start items-center gap-2 mt-4 cursor-pointer"
            onClick={() => navigate("/dashboard/organizations")}
          >
            <div className="grid place-items-center w-[40px] h-[40px] ml-[-5px] bg-[#5E17EB]">
              <GoOrganization
                className="text-3xl"
                style={{
                  color: isActive("/organizations") ? "#FC2947" : "#fff",
                  fontSize: isActive("/organizations") ? 30 : 30,
                }}
              />
            </div>
            <a.h5 style={removeAnimation}>Organizations</a.h5>
          </div>
          <div
            className="flex justify-start items-center gap-2 mt-4 cursor-pointer"
            onClick={() => navigate("/dashboard/brands")}
          >
            <div className="grid place-items-center w-[40px] h-[40px] ml-[-5px] bg-[#5E17EB]">
              <MdOutlineBrandingWatermark
                className="text-3xl"
                style={{
                  color: isActive("/brands") ? "#FC2947" : "#fff",
                  fontSize: isActive("/brands") ? 30 : 30,
                }}
              />
            </div>
            <a.h5 style={removeAnimation}>Brands</a.h5>
          </div>
          <div
            className="flex justify-start items-center gap-2 mt-4 cursor-pointer"
            onClick={() => navigate("/dashboard/locations")}
          >
            <div className="grid place-items-center w-[40px] h-[40px] ml-[-5px] bg-[#5E17EB]">
              <MdOutlineShareLocation
                className="text-3xl"
                style={{
                  color: isActive("/locations") ? "#FC2947" : "#fff",
                  fontSize: isActive("/locations") ? 30 : 30,
                }}
              />
            </div>
            <a.h5 style={removeAnimation}>Locations</a.h5>
          </div>
          <div
            className="flex justify-start items-center gap-2 mt-4 cursor-pointer"
            onClick={() => navigate("/dashboard/warehouses")}
          >
            <div className="grid place-items-center w-[40px] h-[40px] ml-[-5px] bg-[#5E17EB]">
              <MdWarehouse
                className="text-3xl"
                style={{
                  color: isActive("/warehouses") ? "#FC2947" : "#fff",
                  fontSize: isActive("/warehouses") ? 30 : 30,
                }}
              />
            </div>
            <a.h5 style={removeAnimation}>Warehouses</a.h5>
          </div>
          <div
            className="flex justify-start items-center gap-2 mt-4 cursor-pointer"
            onClick={() => navigate("/dashboard/suppliers")}
          >
            <div className="grid place-items-center w-[40px] h-[40px] ml-[-5px] bg-[#5E17EB]">
              <TbTruckDelivery
                className="text-3xl"
                style={{
                  color: isActive("/suppliers") ? "#FC2947" : "#fff",
                  fontSize: isActive("/suppliers") ? 30 : 30,
                }}
              />
            </div>
            <a.h5 style={removeAnimation}>Suppliers</a.h5>
          </div>
          <div
            className="flex justify-start items-center gap-2 mt-4 cursor-pointer"
            onClick={() => navigate("/dashboard/products")}
          >
            <div className="grid place-items-center w-[40px] h-[40px] ml-[-5px] bg-[#5E17EB]">
              <TbPackages
                className="text-3xl"
                style={{
                  color:
                    isActive("/products") && !isActive("/catalogs")
                      ? "#FC2947"
                      : "#fff",
                  fontSize:
                    isActive("/products") && !isActive("/catalogs") ? 30 : 30,
                }}
              />
            </div>
            <a.h5 style={removeAnimation}>Products</a.h5>
          </div>
          <div
            className="flex justify-start items-center gap-2 mt-4 cursor-pointer"
            onClick={() => navigate("/dashboard/catalogs")}
          >
            <div
              style={{
                borderRadius: 0,
                width: 40,
                height: 40,
                marginLeft: -5,
                background: "#5E17EB",
              }}
              className="grid place-items-center"
            >
              <AiOutlineOrderedList
                className="text-3xl"
                style={{
                  color:
                    !isActive("/suppliers") && isActive("/catalogs")
                      ? "#FC2947"
                      : "#fff",
                  fontSize:
                    !isActive("/suppliers") && isActive("/catalogs") ? 30 : 30,
                }}
              />
            </div>
            <a.h5 style={removeAnimation}>Catalogs</a.h5>
          </div>
          <div
            className="flex justify-start items-center gap-2 mt-4 cursor-pointer"
            onClick={() => navigate("/dashboard/factories")}
          >
            <div className="grid place-items-center w-[40px] h-[40px] ml-[-5px] bg-[#5E17EB]">
              <GiFactory
                className="text-3xl"
                style={{
                  color: isActive("/factories") ? "#FC2947" : "#fff",
                  fontSize: isActive("/factories") ? 30 : 30,
                }}
              />
            </div>
            <a.h5 style={removeAnimation}>Factories</a.h5>
          </div>
        </div>
        <div className="px-[20px]">
          <div className="flex">
            {organization?.logo && (
              <img
                src={organization?.logo}
                className="min-w-[40px] w-[40px] h-[40px] -ml-1"
              />
            )}
            {/* <FloatingLabel label="Organization" style={{ minWidth: 200 }}> */}
            <select
              defaultValue={organizationId}
              onChange={(e) => {
                // @ts-expect-error
                setOrganizationId(e.target[e.target.selectedIndex]?.value);
              }}
              className="w-full"
              style={{
                background: "transparent",
                border: "none",
                color: "white",
              }}
            >
              {organizations.length === 0 && (
                <option hidden className="text-black">
                  -- Create Organization --
                </option>
              )}
              {organizations.map((org, idx) => (
                <option value={org.id} key={idx} className="text-black">
                  {org.name}
                </option>
              ))}
            </select>
            {/* </FloatingLabel> */}
          </div>
          <div className="flex flex-col gap-3">
            <a.div
              style={sepratorExpandAnimation}
              className="h-[1px] bg-white mt-4"
            />
            <div
              className="flex justify-start items-center gap-2 mb-4 cursor-pointer"
              onClick={() => navigate("/dashboard/settings")}
            >
              <CiSettings
                className="text-3xl"
                style={{
                  fontSize: 32,
                }}
              />
              <a.h5 style={removeAnimation}>Settings</a.h5>
            </div>
          </div>
        </div>
      </a.div>
    </>
  );
}
